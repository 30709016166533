import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminGuard from 'src/components/AdminGuard';
import AuthGuard from 'src/components/AuthGuard';
import ProjectAdminGuard from 'src/components/ProjectAdminGuard';

import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/surveys',
        component: lazy(() => import('src/views/survey/SurveyListView'))
      },
      {
        exact: true,
        path: '/app/surveys/create',
        component: lazy(() => import('src/views/survey/SurveyCreateView'))
      },
      {
        exact: true,
        path: '/app/surveys/map',
        component: lazy(() => import('src/views/survey/SurveyMapView'))
      },
      {
        exact: true,
        path: '/app/surveys/search/email',
        component: lazy(() =>
          import('src/views/survey/SearchByParticipantEmail')
        )
      },

      {
        exact: true,
        path: '/app/surveys/search/locale',
        component: lazy(() => import('src/views/survey/SearchByLocale'))
      },
      {
        exact: true,
        path: '/app/surveys/search/locale',
        component: lazy(() => import('src/views/survey/SurveySearchView'))
      },
      {
        exact: true,
        path: '/app/surveys/:surveyId',
        component: lazy(() => import('src/views/survey/SurveyDetailView'))
      },
      {
        exact: true,
        path: '/app/surveys/:surveyId/samples/create',
        component: lazy(() => import('src/views/survey/SampleCreateView'))
      },
      {
        exact: true,
        path: '/app/surveys/:surveyId/samples/:sampleId',
        component: lazy(() => import('src/views/survey/SampleDetailView'))
      },
      {
        exact: true,
        path: '/app/surveys/:surveyId/samples/:sampleId/edit',
        component: lazy(() => import('src/views/survey/SampleEditView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: lazy(() => import('src/views/reports/ReportListView'))
      },
      {
        exact: true,
        path: '/app/reports/full',
        component: lazy(() => import('src/views/reports/ReportDetailView'))
      },
      {
        exact: true,
        path: '/app/reports/geojson',
        component: lazy(() => import('src/views/reports/ReportMapView'))
      },

      {
        exact: true,
        path: '/app/reports/mapmyenvironment',
        component: lazy(() =>
          import('src/views/reports/ReportMapMyEnvironmentView')
        )
      },
      {
        exact: true,
        guard: ProjectAdminGuard,
        path: '/app/project',
        component: lazy(() => import('src/views/project/ProjectDetailView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/users',
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/users/create',
        component: lazy(() => import('src/views/user/UserCreateView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/users/:sub',
        component: lazy(() => import('src/views/user/UserDetailView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/projects',
        component: lazy(() => import('src/views/project/ProjectListView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/projects/:projectId',
        component: lazy(() => import('src/views/project/ProjectDetailView'))
      },
      {
        exact: true,
        guard: AdminGuard,
        path: '/app/admin/settings',
        component: lazy(() => import('src/views/project/ProjectSettingsView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/surveys" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    routes: [
      {
        component: () => <Redirect to="/app" />
      }
    ]
  }
];

export default routes;
