import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import axios from '../utils/axios';
import SplashScreen from '../components/SplashScreen';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

const STORAGE_KEY = 'APP::PROJECT_SETTINGS';

const defaultSettings = {
  selectedProject: undefined
};

export const restoreSettings = () => {
  let settings = null;

  try {
    const storedData = window.localStorage.getItem(STORAGE_KEY);

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = settings => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
};

const ProjectContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {}
});

export const ProjectProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(
    settings || defaultSettings
  );

  const [projects, setProjects] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const handleSelectProject = project => {
    const selectedProject = projects.find(
      a => a.identifier === project.identifier
    );
    if (selectedProject) {
      const settings = {
        ...currentSettings,
        selectedProject
      };

      setCurrentSettings(settings);
      storeSettings(settings);

      history.push('/');
    }
  };

  useEffect(() => {
    // load the currentSettings
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }

    const settings = restoredSettings || currentSettings;

    // refresh the available projects
    const getProjects = async startKey => {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + '/v1/projects',
        {
          params: {
            startKey
          }
        }
      );
      const { results, pagingKeys } = response.data;

      if (pagingKeys.next) {
        // get the next page and join the results
        const { results: nextResults } = await getProjects(pagingKeys.next);
        results = results.concat(nextResults);
      }

      // set the projects
      setProjects(results);

      // select the first project
      if (!settings.selectedProject) {
        setCurrentSettings({
          selectedProject: results[0]
        });
      }

      // check that the selected project is still available to the user
      else if (
        settings.selectedProject &&
        !results.find(
          result => result.identifier === settings.selectedProject.identifier
        )
      ) {
        // this project is no longer available to the user - select the first available one
        setCurrentSettings({
          selectedProject: results[0]
        });
      }
    };

    getProjects();
  }, [user]);

  return (
    <ProjectContext.Provider
      value={{
        selectedProject: currentSettings.selectedProject,
        projects: projects,
        selectProject: handleSelectProject
      }}
    >
      {/* {children} */}
      {currentSettings.selectedProject ? children : <SplashScreen />}
    </ProjectContext.Provider>
  );
};

export const ProjectConsumer = ProjectContext.Consumer;

export default ProjectContext;
