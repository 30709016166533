import axios from 'axios';
import * as cognito from './cognito';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  config => {
    return new Promise(async (resolve, reject) => {
      try {
        const accessToken = await cognito.getAccessToken();

        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      } catch (err) {
        console.log(err);
      } finally {
        resolve(config);
      }
    });
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return new Promise(async (resolve, reject) => {
      console.trace('Error response from server');
      console.trace(error);

      if (!error.response) {
        // not a http error
        console.error(error);
      } else {
        if (error.response.status === 401) {
          // unauthorized
          const originalRequest = error.config;
          if (!originalRequest.isRetried) {
            // refresh the user session
            try {
              // refresh the session
              await cognito.refreshSession();

              // get access token
              const accessToken = await cognito.getAccessToken();

              // set the header
              axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
              originalRequest.headers.Authorization = `Bearer ${accessToken}`;

              // mark as already retried so we don't retry again
              originalRequest.isRetried = true;

              // retry
              resolve(axios(originalRequest));
            } catch (error) {
              console.log('Error from server:');
              console.trace(error);

              reject((error.response && error.response.data) || 'Unauthorised');
            }
          } else {
            // throw
            reject((error.response && error.response.data) || 'Unauthorised');
          }
        } else if (error.response.status === 403) {
          // forbidden
          reject((error.response && error.response.data) || 'Forbidden');
        } else {
          reject(
            (error.response && error.response.data) || 'Internal server error'
          );
        }
      }
    });
  }
);

export default axiosInstance;
