/* eslint-disable no-use-before-define */
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  BarChart as BarChartIcon,
  Briefcase as BriefcaseIcon,
  Clipboard as ClipboardIcon,
  Map as MapIcon,
  PieChart as PieChartIcon,
  Search as SearchIcon,
  Sliders as SlidersIcon,
  Users as UsersIcon,
  Settings as SettingsIcon
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import useProject from 'src/hooks/useProject';

import NavItem from './NavItem';
import ProjectDialog from './ProjectDialog';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    // cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { projects, selectedProject, selectProject } = useProject();

  const [open, setOpen] = React.useState(false);

  console.log(user);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    if (value) {
      selectProject(value);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <Avatar alt={selectedProject.identifier} className={classes.avatar}>
              {selectedProject.identifier}
            </Avatar>
          </Box>
          <Box mt={1} textAlign="center">
            <Typography variant="h5" color="textPrimary">
              {selectedProject.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <Link onClick={handleClickOpen} component="button">
                Switch
              </Link>
            </Typography>
            <ProjectDialog
              selectedValue={selectedProject}
              open={open}
              onClose={handleClose}
            />
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                Surveys
              </ListSubheader>
            }
          >
            <List disablePadding>
              <NavItem
                depth={0}
                href="/app/surveys"
                icon={BarChartIcon}
                title="List"
              />
              <NavItem depth={0} icon={SearchIcon} title="Search">
                <NavItem
                  depth={1}
                  href="/app/surveys/search/email"
                  title="By email"
                />
                <NavItem
                  depth={1}
                  href="/app/surveys/search/locale"
                  title="By locale"
                />
              </NavItem>
            </List>
          </List>
          <List
            subheader={
              <ListSubheader disableGutters disableSticky>
                Reports
              </ListSubheader>
            }
          >
            <List disablePadding>
              <NavItem
                depth={0}
                href="/app/reports/full"
                icon={ClipboardIcon}
                title="Full"
              />
              <NavItem
                depth={0}
                href="/app/reports/geojson"
                icon={MapIcon}
                title="GeoJson"
              />
              <NavItem
                depth={0}
                href="/app/reports/mapmyenvironment"
                icon={ClipboardIcon}
                title="Map My Environment"
              />
            </List>
          </List>
        </Box>
        {user &&
          user.groups &&
          (user.groups.indexOf(`${selectedProject.identifier}#ADMIN`) !== -1 ||
            user.groups.indexOf(`APP#ADMIN`) !== -1) && (
            <>
              <Divider />
              <Box p={2}>
                <List
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      Administration
                    </ListSubheader>
                  }
                >
                  <List disablePadding>
                    {user.groups.indexOf(
                      `${selectedProject.identifier}#ADMIN`
                    ) !== -1 && (
                      <NavItem
                        depth={0}
                        href="/app/project"
                        icon={SettingsIcon}
                        title="Project"
                      />
                    )}
                    {user.groups.indexOf(`APP#ADMIN`) !== -1 && (
                      <>
                        <NavItem
                          depth={0}
                          href="/app/admin/users"
                          icon={UsersIcon}
                          title="Users"
                        />
                        <NavItem
                          depth={0}
                          href="/app/admin/projects"
                          icon={BriefcaseIcon}
                          title="Projects"
                        />
                      </>
                    )}
                  </List>
                </List>
              </Box>
            </>
          )}
        <Divider />
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <Typography variant="caption" color="textPrimary">
              &copy; {new Date().getFullYear()} -{' '}
              <Link
                color="inherit"
                href="https://au.linkedin.com/in/mark-patrick-taylor-751b634"
              >
                Mark Patrick Taylor
              </Link>
            </Typography>
          </Box>
        </Box>
        <Box p={1}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <Typography variant="caption" color="textPrimary">
              <Link
                color="inherit"
                href="https://darb.io?ref=%40tsz-next%2Ffrontend"
                target="_child"
              >
                Built with{' '}
                <span role="img" aria-label="love">
                  ❤️
                </span>{' '}
                in{' '}
                <span role="img" aria-label="Australia">
                  🇦🇺
                </span>{' '}
                by darb.io
              </Link>
            </Typography>
            <Typography variant="caption" color="textPrimary">
              v{process.env.REACT_APP_VERSION || 'APP_VERSION'}
            </Typography>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
