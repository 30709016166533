import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {}
}));

const NewPasswordForm = ({ className, nextStage, email, ...rest }) => {
  const classes = useStyles();
  const { resetPasswordWithCode } = useAuth();
  const history = useHistory();
  const isMountedRef = useIsMountedRef();

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      key="newPasswordForm"
      initialValues={{
        email,
        code: '',
        password: '',
        confirmPassword: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().required('Code is required'),
        password: Yup.string()
          .required('Password is required')
          .min(6, 'Minimum length is 6'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords do not match'
        )
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await resetPasswordWithCode(
            values.email,
            values.code,
            values.password
          );

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);

            history.push('/login');
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mb={3}>
              <Alert severity="error">
                <AlertTitle>Password reset failed.</AlertTitle>
                {errors.submit}
              </Alert>
            </Box>
          )}
          <Box mb={2}>
            <Alert severity="info">
              <AlertTitle>Check your email.</AlertTitle>
              Please check your email for a verification code and enter it
              below.
            </Alert>
          </Box>
          <TextField
            error={Boolean(touched.code && errors.code)}
            fullWidth
            autoFocus
            helperText={touched.code && errors.code}
            label="Verification code"
            margin="normal"
            name="code"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.code}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="New password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Confirm new password"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
          <Box mt={2} mb={1}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Reset Password
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

NewPasswordForm.propTypes = {
  className: PropTypes.string
};

export default NewPasswordForm;
