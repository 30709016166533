import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js';
import * as util from 'util';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL,
  ClientId: process.env.REACT_APP_COGNITO_CLIENTID
});

export const getAccessToken = async () => {
  const user = userPool.getCurrentUser();

  if (!user) throw new Error('no user');

  const getSession = user =>
    new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) reject(err);
        else resolve(session);
      });
    });
  const session = await getSession(user);

  return session.getAccessToken().getJwtToken();
};

export const logout = async () => {
  userPool.getCurrentUser().signOut();
};

export const login = async (email, password) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool
  });

  // promisify the aws sdk
  const authenticateUser = () =>
    new Promise((resolve, reject) => {
      user.authenticateUser(
        new AuthenticationDetails({
          Username: email,
          Password: password
        }),
        {
          onSuccess: resolve,
          onFailure: reject,
          mfaRequired: codeDeliveryDetails => {
            resolve('mfaRequired');
          },
          newPasswordRequired: (userAttributes, requiredAttributes) => {
            resolve('newPasswordRequired');
          }
        }
      );
    });
  const getUserAttributes = util.promisify(user.getUserAttributes).bind(user);

  let accessToken;
  try {
    // get the current session
    const result = await authenticateUser(
      new AuthenticationDetails({
        Username: email,
        Password: password
      })
    );

    if (result === 'newPasswordRequired') {
      // User was signed up by an admin and must provide new
      // password and required attributes, if any, to complete
      // authentication.
      return 'newPasswordRequired';
    } else if (result === 'mfaRequired') {
      throw new Error('MFA required (not implemented).');
    } else {
      accessToken = result.getAccessToken();
    }
  } catch (err) {
    if (err.code === 'NotAuthorizedException') {
      throw new Error(err.message || 'Username or password is incorrect.');
    } else {
      throw err;
    }
  }

  // get the user attributes
  const attributes = await getUserAttributes();

  // get the groups for the user
  const groups = accessToken.payload['cognito:groups'];

  // user object as follows
  // user: {
  //   id: user.id,
  //   avatar: user.avatar,
  //   email: user.email,
  //   name: user.name,
  //   tier: user.tier
  // }
  return {
    id: attributes.find(a => a.Name === 'sub').Value,
    avatar: '',
    email: attributes.find(a => a.Name === 'email').Value,
    name: `${attributes.find(a => a.Name === 'given_name').Value} ${
      attributes.find(a => a.Name === 'family_name').Value
    }`,
    groups
  };
};

export const refreshSession = async () => {
  const user = userPool.getCurrentUser();

  if (!user) throw new Error('no user');

  // get the current session
  const getSession = user =>
    new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) reject(err);
        else resolve(session);
      });
    });
  const session = await getSession(user);

  // refresh the session
  const refreshSession = refreshToken =>
    new Promise((resolve, reject) => {
      user.refreshSession(refreshToken, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  const newSession = await refreshSession(session.refreshToken);

  // get the jwt
  const accessToken = newSession.getAccessToken();

  // set the user session
  await getSession(userPool.getCurrentUser()); // IMPORTANT - https://github.com/amazon-archives/amazon-cognito-identity-js/issues/35#issuecomment-266633372

  // get the user attributes
  const getUserAttributes = () =>
    new Promise((resolve, reject) => {
      user.getUserAttributes((err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  const attributes = await getUserAttributes();

  // get the groups for the user
  const groups = accessToken.payload['cognito:groups'];

  // user object as follows
  // user: {
  //   id: user.id,
  //   avatar: user.avatar,
  //   email: user.email,
  //   name: user.name,
  //   tier: user.tier
  // }
  return {
    id: attributes.find(a => a.Name === 'sub').Value,
    avatar: '',
    email: attributes.find(a => a.Name === 'email').Value,
    name: `${attributes.find(a => a.Name === 'given_name').Value} ${
      attributes.find(a => a.Name === 'family_name').Value
    }`,
    groups
  };
};
