import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';

import EmailAddressForm from './EmailAddressForm';
import NewPasswordForm from './NewPasswordForm';

const stages = {
  getCode: 'getCode',
  resetPassword: 'resetPassword'
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));

const PasswordResetView = () => {
  const classes = useStyles();
  const { method } = useAuth();

  const [stage, setStage] = useState(stages.getCode);
  const nextStage = email => {
    setEmail(email);
    if (stage === stages.getCode) {
      setStage(stages.resetPassword);
    }
  };

  const [email, setEmail] = useState();

  return (
    <Page className={classes.root} title="Forgotten Password">
      <Container className={classes.cardContainer} maxWidth="sm">
        <Card>
          <CardContent className={classes.cardContent}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h2">
                  Forgotten password
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Reset your password using your email address.
                </Typography>
              </div>
            </Box>
            <Box flexGrow={1} mb={3}>
              <Alert severity="warning">
                <AlertTitle>Restricted access.</AlertTitle>
                <p>
                  This system is restricted to authorised users. Individuals who
                  attempt unauthorised access will be prosecuted. If you are
                  unauthorised, terminate access now.
                </p>
              </Alert>
            </Box>
            <Box flexGrow={1}>
              {stage === stages.getCode ? (
                <EmailAddressForm nextStage={nextStage} />
              ) : (
                <NewPasswordForm nextStage={nextStage} email={email} />
              )}
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to="/login"
              variant="body2"
              color="textSecondary"
            >
              Login
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default PasswordResetView;
