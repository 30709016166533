import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';

import JWTLogin from './JWTLogin';
import CompleteSignUpForm from './CompleteSignUpForm';

const stages = {
  login: 'login',
  completeSignup: 'completeSignup'
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column'
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const { method } = useAuth();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [stage, setStage] = useState(stages.login);
  const next = (email, password) => {
    setEmail(email);
    setPassword(password);

    if (stage === stages.login) {
      setStage(stages.completeSignup);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Container className={classes.cardContainer} maxWidth="sm">
        <Card>
          <CardContent className={classes.cardContent}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              mb={3}
            >
              <div>
                <Typography color="textPrimary" gutterBottom variant="h2">
                  Sign in
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Sign in using your email address and password.
                </Typography>
              </div>
            </Box>
            <Box flexGrow={1} mb={3}>
              <Alert severity="warning">
                <AlertTitle>Restricted access.</AlertTitle>
                <p>
                  This system is restricted to authorised users. Individuals who
                  attempt unauthorised access will be prosecuted. If you are
                  unauthorised, terminate access now.
                </p>
              </Alert>
            </Box>
            <Box flexGrow={1}>
              {stage === stages.login ? (
                <JWTLogin nextStage={next} />
              ) : (
                <CompleteSignUpForm email={email} password={password} />
              )}
            </Box>
            <Box my={3}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to="/forgotten_password"
              variant="body2"
              color="textSecondary"
            >
              Forgot your password?
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default LoginView;
