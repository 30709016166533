import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useProject from '../hooks/useProject';

const ProjectAdminGuard = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const { selectedProject } = useProject();

  if (
    !isAuthenticated ||
    user.groups.indexOf(`${selectedProject.identifier}#ADMIN`) === -1
  ) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

ProjectAdminGuard.propTypes = {
  children: PropTypes.node
};

export default ProjectAdminGuard;
