import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as Yup from 'yup';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EmailAddressForm = ({ className, nextStage, ...rest }) => {
  const classes = useStyles();
  const { getPasswordResetCode } = useAuth();
  const isMountedRef = useIsMountedRef();

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      key="emailAddressForm"
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await getPasswordResetCode(values.email);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);

            nextStage(values.email);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={clsx(classes.root, className)}
          {...rest}
        >
          {errors.submit && (
            <Box mb={3}>
              <Alert severity="error">
                <AlertTitle>Password reset failed.</AlertTitle>
                {errors.submit}
              </Alert>
            </Box>
          )}
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            autoFocus
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <Box mt={2} mb={1}>
            <Button
              color="secondary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Get a code
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

EmailAddressForm.propTypes = {
  className: PropTypes.string
};

export default EmailAddressForm;
