import MomentUtils from '@date-io/moment';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { ProjectProvider } from 'src/contexts/ProjectContext';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import routes, { renderRoutes } from 'src/routes';
import { createTheme } from 'src/theme';
import LoginView from 'src/views/auth/LoginView';
import PasswordResetView from 'src/views/auth/PasswordResetView';
import RegisterView from 'src/views/auth/RegisterView';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Router history={history}>
              <AuthProvider>
                <Application />
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

const Application = () => {
  const { user } = useAuth();

  if (user) {
    Sentry.setUser({ sub: user.id, email: user.email });
  }

  if (!user) {
    return (
      <Switch>
        <Route path="/login">
          <LoginView />
        </Route>
        <Route path="/forgotten_password">
          <PasswordResetView />
        </Route>
        <Route path="/register">
          <RegisterView />
        </Route>
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    );
  }

  return (
    <>
      <GlobalStyles />
      <ScrollReset />
      <GoogleAnalytics />
      {/* <CookiesNotification /> */}
      {/* <SettingsNotification /> */}
      <ProjectProvider>
        <Sentry.ErrorBoundary
          fallback={({ error, componentStack, resetError }) => (
            <React.Fragment>
              <div>You have encountered an error</div>
              <div>{error.toString()}</div>
              <div>{componentStack}</div>
              <button
                onClick={() => {
                  resetError();
                }}
              >
                Click here to reset!
              </button>
            </React.Fragment>
          )}
          showDialog
        >
          {renderRoutes(routes)}
        </Sentry.ErrorBoundary>
      </ProjectProvider>
    </>
  );
};

export default App;
