import 'nprogress/nprogress.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'src/App';
import * as serviceWorker from 'src/serviceWorker';

Sentry.init({
  dsn:
    'https://395c946a7e684dc5b94569549b5544e4@o95817.ingest.sentry.io/5849002',
  environment: process.env.REACT_APP_ENV || 'develop',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

enableES5();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();
