import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';

import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser
} from 'amazon-cognito-identity-js';

import { useHistory } from 'react-router-dom';

import * as cognito from '../utils/cognito';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL,
  ClientId: process.env.REACT_APP_COGNITO_CLIENTID
});

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        isInitialised: true,
        user: action.payload.user
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    // case 'REGISTER': {
    //   const { user } = action.payload;

    //   return {
    //     ...state,
    //     isAuthenticated: true,
    //     user
    //   };
    // }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  // register: () => Promise.resolve(),
  getPasswordResetCode: () => Promise.resolve(),
  resetPasswordWithCode: () => Promise.resolve(),
  handleNewPassword: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const history = useHistory();

  const login = async (email, password) => {
    try {
      const result = await cognito.login(email, password);

      if (result === 'newPasswordRequired') {
        return 'ADMIN_COMPLETE_SIGNUP';
      } else if (result === 'mfaRequired') {
        return 'MFA_REQUIRED';
      } else {
        dispatch({
          type: 'LOGIN',
          payload: {
            user: result
          }
        });
      }
    } catch (err) {
      dispatch({
        type: 'LOGOUT'
      });

      throw err;
    }
  };

  // TODO move to ../util/cognito.js
  const handleNewPassword = (
    email,
    password,
    newPassword,
    family_name,
    given_name
  ) => {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: email,
        Pool: userPool
      };

      const cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: email,
          Password: password
        }),
        {
          onFailure: err => reject(err),
          newPasswordRequired: () => {
            cognitoUser.completeNewPasswordChallenge(
              newPassword,
              {
                email,
                family_name,
                given_name
              },
              {
                onSuccess: session => {
                  const accessToken = session.getAccessToken();

                  cognitoUser.getUserAttributes((err, result) => {
                    if (err) reject(err);

                    // user object as follows
                    // user: {
                    //   id: user.id,
                    //   avatar: user.avatar,
                    //   email: user.email,
                    //   name: user.name,
                    //   tier: user.tier
                    // }
                    const user = {
                      id: result.find(a => a.Name === 'sub').Value,
                      avatar: '',
                      email: result.find(a => a.Name === 'email').Value,
                      name: `${
                        result.find(a => a.Name === 'given_name').Value
                      } ${result.find(a => a.Name === 'family_name').Value}`,
                      groups: accessToken.payload['cognito:groups'] || []
                    };

                    dispatch({
                      type: 'LOGIN',
                      payload: {
                        user
                      }
                    });

                    resolve('SUCCESS');
                  });
                },
                onFailure: err => {
                  reject(err);
                }
              }
            );
          }
        }
      );
    });
  };

  const logout = () => {
    try {
      cognito.logout();
    } catch (err) {
      console.error(err);
    } finally {
      dispatch({ type: 'LOGOUT' });
      history.push(0);
    }
  };

  // TODO move to ../util/cognito.js
  const getPasswordResetCode = async email => {
    return new Promise((resolve, reject) => {
      try {
        // call cognito
        const userData = {
          Username: email,
          Pool: userPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
          onSuccess: data => {
            resolve();
          },
          onFailure: err => {
            reject(err);
          }
        });
      } catch (err) {
        throw err;
      }
    });
  };

  // TODO move to ../util/cognito.js
  const resetPasswordWithCode = async (email, code, newPassword) => {
    return new Promise((resolve, reject) => {
      try {
        // call cognito
        const userData = {
          Username: email,
          Pool: userPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(code, newPassword, {
          onSuccess: () => {
            login(email, newPassword);
          },
          onFailure: err => {
            reject(err);
          }
        });
      } catch (err) {
        throw err;
      }
    });
  };

  const refreshSession = async () => {
    try {
      const result = await cognito.refreshSession();

      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: true,
          user: result
        }
      });
    } catch (err) {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    refreshSession();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        // register,
        getPasswordResetCode,
        resetPasswordWithCode,
        handleNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
