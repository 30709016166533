import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import useProject from 'src/hooks/useProject';

const useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
    fontSize: '0.75em'
  }
});

const ProjectDialog = props => {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const { projects } = useProject();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="simple-dialog-title">Choose project</DialogTitle>
      <List>
        {projects.map(project => (
          <ListItem
            button
            onClick={() => handleListItemClick(project)}
            key={project.identifier}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{project.identifier}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={project.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

ProjectDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.shape({
    name: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired
  }).isRequired
};

export default ProjectDialog;
